<template>
  <div class="page-content">
    <b-row>
      <b-col md="10">
        <page-breadcrumb title="Users import" class="mb-3" />
      </b-col>
    </b-row>
    <h2 class="mb-2">Users import</h2>
    <div>
      <span class="label-form">List email: </span>
      <input type="file" id="files" ref="files" @change="handleFilesUpload">
    </div>
    <div class="mt-2">
      <b-button variant="success" :disabled="!excelData" @click="upload">Save</b-button>
    </div>
  </div>
</template>
<script>
import XLSX from 'xlsx'
import service from '../service'
export default {
  data(){
    return{
      importFile: null,
      excelData: null
    }
  },
  methods:{
    handleFilesUpload(e){
      var input = e.target;
      var reader = new FileReader();
      reader.onload = () => {
        var fileData = reader.result;
        var wb = XLSX.read(fileData, {type : 'binary'});
        wb.SheetNames.forEach((sheetName) => {
	        var rowObj =XLSX.utils.sheet_to_json(wb.Sheets[sheetName]);
          this.excelData = JSON.stringify(rowObj)
        })
      };
      reader.readAsBinaryString(input.files[0]);
    },
    async upload(){
      await service.import(this.excelData)
    }
  }
}
</script>
