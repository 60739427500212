import api from '@/libs/axios'

export default {
  async import(payload) {
    const response = await api.post('/admin/user-play-time-import', payload)
    return response || null
  },
  async delete(payload) {
    const response = await api.delete('/admin/user-play-time-import', {
      data: payload,
    })
    return response
  },
  async getList(params) {
    let response = await api.get('/admin/user-play-time-imports', {
      params,
    })
    return response
  }
}
